import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '../Button';

import * as GlobalCSS from '../../styles.module.css';
import Wrapper from '../Wrapper';
import * as CSS from './Menu.module.css';

function Menu() {
  return (
    <Wrapper className="bgMenu" id="menu">
      <div className={GlobalCSS.textLogo}><FormattedMessage id="menuTitle" /></div>

      <div className={CSS.menu}>
        <Button to="/menu/cachimba/" type="link" className={CSS.menuLink}>
          <FormattedMessage id="menuHookah" />
        </Button>
        <Button to="/menu/" type="link" className={CSS.menuLink}>
          <FormattedMessage id="menuDrinks" />
        </Button>
      </div>
    </Wrapper>
  );
}

export default Menu;
