import React from 'react';
import Helmet from 'react-helmet';
import Menu from '../views/Menu';
// import Reservation from 'components/views/Reservation';
// import Reviews from 'components/views/Reviews';
// import Media from 'components/views/Media';
import Information from '../views/Information';

// function IndexPageOld() {
//   return (
//     <>
//       <Menu />
//       <Reservation />
//       <Reviews />
//       <Media />
//       <Information />
//     </>
//   );
// }

function IndexPage() {
  return (
    <>
      <Helmet
        title="Hookah Base - Shisha Lounge & Cocktails Bar"
      />

      <Menu />
      <Information />
    </>
  );
}

export default IndexPage;
